import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 4px 0;
`

const ChoiceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Choice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.8;
  padding: 6px 10px;
  font-size: 16px;
  font-family: 'Averia Serif Libre', cursive;
  border-radius: 4px;
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  background: rgb(240,238,235);
  margin: 2px 2px;
  cursor: pointer;

  > svg{
    font-size: 20px;
    margin-right: 10px;
  }

  > span{
    margin-bottom: -2px;
  }

  ${({ choosen }) => choosen && `background: #f9ca24;`}
  &:hover{background: #f9ca24;}

`

const Title = styled.span`
  font-size: 16px;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: normal;
  opacity: 0.8;
  margin-left: 4px;
  margin-bottom: 2px;
`

const ChoiceSelector = ({ title, choices, selectedValue, onChange }) => {

  return (
    <Wrapper>
      <Title>{title}</Title>
      <ChoiceWrapper>
        {choices.filter(i => Boolean(i.name)).map(({ name, icon, value }) => <Choice key={name} onClick={() => onChange(value)} choosen={value === selectedValue}><FontAwesomeIcon icon={["fal", icon]} /><span>{name}</span></Choice>)}
      </ChoiceWrapper>
    </Wrapper>
  )
}

export default ChoiceSelector
