import React from 'react'
import styled from 'styled-components'

const Outer = styled.button`
  padding: 15px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-family: 'Averia Serif Libre', cursive;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.75);
  border: 1px solid transparent;
  margin: 0 4px;
  text-align: center;
  text-decoration: none;

  &:hover{
    color: #eee;
    opacity: 0.6;
  }

  ${({ inverse }) => inverse && `
    background: none;
    border: 1px solid rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
  `}

`

const Button = ({ children, inverse, onClick, as, style, ...rest }) => {

  return (
    <Outer as={as} style={style} inverse={inverse} {...rest} onClick={onClick}>{children}</Outer>
  )
}

export default Button
