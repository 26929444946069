import { createGlobalStyle } from 'styled-components'
import "react-datetime/css/react-datetime.css"

export default createGlobalStyle`

  html{
    height: 100%;
  }

  body {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.primary_text};
    font-family: 'Roboto Mono', monospace;
    position: relative;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  body *{
    box-sizing: border-box;
  }

  body a{
    color: ${({ theme }) => theme.primary_text};
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
  }

  body a:hover{
    color: ${({ theme }) => theme.primary_text};
    text-decoration: none;
  }

  #root{
    min-height: 100vh;
    width: 100vw;
  }

  .my-masonry-grid {
    display: flex;
    margin-left: 0; /* gutter size offset */
    width: auto;
    margin: 0 auto;
  }
  .my-masonry-grid_column {
    padding-left: 0; /* gutter size */
    background-clip: padding-box;
  }

  .my-masonry-grid_column:first-child {
    padding-left: 0;
  }

  .my-masonry-grid_column > div {margin: 15px auto;}

`
