
import { history } from "../Router"

import { USE_REFRESH_FOR_PAGE_CHANGE } from '../constants/endpoints'
//Workaround för Holid.js scriptet

const GoTo = (path) => {
    console.log("gotod")

    if(USE_REFRESH_FOR_PAGE_CHANGE){
        history.push(path)
        window.location.reload()
    } else {
        history.push(path)
    }

    return null

}

export default GoTo