import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'

import { Page } from '../style/wrappers'
import { GET_FROM_COORDS_URL } from '../constants/endpoints'

import Header from '../containers/Header'
import VerticalDesktopAd from '../containers/ads/VerticalDesktopAd'
import StationWrapper from '../containers/StationWrapper'

import Button from '../components/Button'
import Footer from '../components/Footer'

import { setGeolocationAvailable, setCurrentGeolocation, setChoosenStation } from '../actions/ui'

const OuterPage = styled.div`
  display: flex;
`

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5% 0;
`

const ContentWrapper = styled.div`
  position: relative;
`


const StationCurtain = styled.div`
  position: relative;
  opacity: ${({ show }) => show ? 1 : 0};
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
`

const Overlay = styled.div`
  position: absolute;
  top: 5%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

`

const Title = styled.span`
  font-size: 28px;
  font-family: 'Averia Serif Libre', cursive;
  text-align: center;
  font-weight: bold;
  opacity: 0.8;
`

const Description = styled.span`
  font-size: 16px;
  font-family: 'Averia Serif Libre', cursive;
  text-align: center;
  font-weight: bold;
  opacity: 0.8;
  padding: 5px 0 15px 0;
`

const LocationImage = styled.img`
width: 200px;
margin: 15px 0;
`

const Closest = () => {
  const dispatch = useDispatch()
  const geolocation_available = useSelector(state => state.ui.geolocation_available)
  const [foundStation, setFoundStation] = useState(null)

  const getPos = useCallback(() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
 
      const response = await axios({
         url: GET_FROM_COORDS_URL,
         params: {lat: position.coords.latitude, lon: position.coords.longitude},
         timeout: 5000
       })

       if(response?.data?.stations[0]?.id){
          setFoundStation(response?.data?.stations[0]?.id)
       }

    })
  }, [])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
 
      const response = await axios({
         url: GET_FROM_COORDS_URL,
         params: {lat: position.coords.latitude, lon: position.coords.longitude},
         timeout: 5000
       })

       if(response?.data?.stations[0]?.id){
          setFoundStation(response?.data?.stations[0])
       }

    })
  }, [])

  useEffect(() => {
    // check if geolocation is even available in the browser
    if ("geolocation" in navigator) {dispatch(setGeolocationAvailable(true))}
    else {dispatch(setGeolocationAvailable(false))}
  }, [dispatch, geolocation_available])


  return (
    <OuterPage>
      <VerticalDesktopAd left />
      <Page>
        <Helmet>
          <title>Station | Temperatur.nu</title>
        </Helmet>
        <Outer>
          <Header />

          <ContentWrapper>
            {!foundStation && <Overlay>

              <LocationImage style={{cursor: 'pointer'}} onClick={getPos} src={geolocation_available ? `${process.env.PUBLIC_URL}/map.png`: `${process.env.PUBLIC_URL}/warning.png`} width="200" height="200" />
              <Title onClick={getPos}>Hittar din närmaste station</Title>
              <Description onClick={getPos}>Du kan behöva tillåta position i din webbläsare</Description>
              {geolocation_available && <FontAwesomeIcon size="4x" icon={['fal', 'spinner-third']} spin />}

            </Overlay>}
            <StationCurtain show={foundStation}>
              <StationWrapper dist={foundStation?.dist} stationName={foundStation?.id} />
            </StationCurtain>
          </ContentWrapper>

          <Footer />
        </Outer>
      </Page>
      <VerticalDesktopAd right />
    </OuterPage>
  )
}

export default Closest
