import React from 'react'
import styled from 'styled-components'
// import { useDispatch } from 'react-redux'

import ShadowBox from '../components/ShadowBox'
import IndicatorBox from '../components/IndicatorBox'

const Inner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  ${({ standing }) => standing && `
    width: auto;
    flex-direction: column;
  `}

`

const WheatherImage = styled.img`
  height: 65px;
  margin-left: 5px;
  margin-bottom: -2px;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }

`

const LeftSide = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    margin: 5px auto 5px 0;
  }

  ${({ standing }) => standing && `
    margin: 5px auto 5px 0;
  `}

`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  font-size: 25px;
  font-family: 'Averia Serif Libre', cursive;

  span{font-size: 25px;}
  small{
    font-size: 18px;
    opacity: 0.7;
    margin-top: -4px;
  }

`

const RightSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: auto;
  }

  ${({ standing }) => standing && `
    margin-right: auto;
  `}

`

const PrognosisRow = ({ title, date, symbol, temp, precipitation, wind, sunrise, sunset, standing = false }) => {

  return (
    <ShadowBox small style={{ padding: '5px 5px', boxShadow: 'none' }}>
      <Inner standing={standing}>
        <LeftSide standing={standing}>
          {symbol && <WheatherImage src={`${process.env.PUBLIC_URL}/weather/${symbol}.svg`} width="65" height="65" />}
          <Info>
            <span>{title}</span>
            <small>{date}</small>
          </Info>
        </LeftSide>
        <RightSide standing={standing}>
          <IndicatorBox icon="thermometer-half">{temp}°C</IndicatorBox>
          <IndicatorBox icon="umbrella">{precipitation} mm</IndicatorBox>
          <IndicatorBox icon="wind">{wind} m/s</IndicatorBox>
          {sunrise && <IndicatorBox icon="sunrise">{sunrise}</IndicatorBox>}
          {sunset && <IndicatorBox icon="sunset">{sunset}</IndicatorBox>}
        </RightSide>
      </Inner>
    </ShadowBox>
  )
}

export default PrognosisRow
