import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import Datetime from 'react-datetime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, Tooltip, CartesianGrid } from 'recharts'

import SearchBar from '../containers/SearchBar'
import ChoiceSelector from '../components/ChoiceSelector'

import { GRAPH_DATA_URL } from '../constants/endpoints'

const Inner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 609px;

/*@media only screen and (max-width: 768px) {
  min-height: 798px;

  }*/

`

const ChartWrapper = styled.div`
  background: #fff;
  position: relative;
  padding: 25px 10px 15px 10px;
  border: 1px solid rgba(0,0,0,0.45);
  border-radius: 8px;
  box-shadow: 0 4px 3px rgba(0,0,0,0.05), 0 -1px 3px rgba(0,0,0,0.06);
  margin: 15px 0 0 0;
  //width: 735px;

  opacity: ${({ isLoading }) => isLoading ? 0 : 1};
  transition: 0.3s ease;

`

const SelectorPositioner = styled.div`
  margin: 10px 0;
`

const DatetimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  span {
    opacity: 0.6;
    margin: 0 15px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    > span{
      margin: 5px 0;
    }
  }

`

const DatetimeSelector = styled(Datetime)`
  input {
    font-size: 18px;
    padding: 8px 5px;
    border: 1px solid;
    border-radius: 4px;
  }

`

const ArrowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.75;
  padding: 4px 40px;
  font-size: 24px;
  font-family: 'Averia Serif Libre', cursive;
  border-radius: 4px;
  color: ${({ color }) => color};
  border: 1px solid;
  background: #fff;
  margin: 2px 2px;
  cursor: pointer;
  &:hover{opacity: ${({ disabled }) => !disabled && 1};}
  ${({ disabled }) => disabled && 'opacity: 0.1;'}
`

const GraphTitle = styled.span`
  position: absolute;
  top: -21px;
  left: 15px;
  font-size: 16px;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 10px 1px 10px;
  margin: 5px 0;
  border: 1px solid rgba(0,0,0,0.45);
  border-radius: 5px;
  background: #f5f5f6;
`

const LegenderWrap = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto 5px auto;


`

const Legender = styled.div`
  display: flex;
  margin-right: auto;

  p{
    font-size: 13px;
    margin: 0 12px;
  }

  small {
    opacity: 0.6;
  }

`

const LegendDoot = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background: ${({ color }) => color};
  margin-top: 2px;
`

const TempChart = ({ stationName }) => {
  const choosen_station = useSelector(state => state.ui.choosen_station)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [shift, setShift] = useState(0)
  const [chartSpan, setChartSpan] = useState('day')
  const [comparisonMode, setComparisonMode] = useState('')
  const [stationCompare, setStationCompare] = useState(null)
  const [yearCompare, setYearCompare] = useState(null)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  console.log('1', stationName)
  console.log('2', choosen_station)

  useEffect(() => {
    const chartSpanFromStorage = localStorage.getItem('chartSpan')
    if(chartSpanFromStorage && chartSpanFromStorage !== 'null'){
      setChartSpan(localStorage.getItem('chartSpan'))
    }
  }, [])

  useEffect(() => {
    if(chartSpan && chartSpan !== ""){
      localStorage.setItem('chartSpan', chartSpan)
    }
  }, [chartSpan])

  useEffect(() => {
    const comparisonModeFromStorage = localStorage.getItem('comparisonMode')
    if(comparisonModeFromStorage && comparisonModeFromStorage !== 'null'){
      setComparisonMode(localStorage.getItem('comparisonMode'))
    }
  }, [])

  useEffect(() => {
    if(comparisonMode !== "year" && comparisonMode !== "station" && comparisonMode !== "favorite"){
      localStorage.setItem('comparisonMode', comparisonMode)
    } else {
    localStorage.setItem('comparisonMode', "")
    }

  }, [comparisonMode])

  useEffect(() => {
    if(stationName || choosen_station){
      (async () => {
        setLoading(true)
        try{
          const response = await axios({
             url: GRAPH_DATA_URL,
             params: {
               p: comparisonMode === "favorite" ? `${stationName},${choosen_station}`  : `${stationName || choosen_station}${stationCompare ? ',' : ''}${stationCompare || ''}`,
               span: chartSpan ? `1${chartSpan}` : null,
               shift: shift,
               history: yearCompare ? yearCompare : comparisonMode === 'previous_period' ? '' : null,
               start: chartSpan === null ? startDate : null,
               end: chartSpan === null ? endDate : null
             },
             timeout: 5000
           })
           if(response?.data?.stations){
            setData(response.data.stations)
           }
        } catch{}
        setLoading(false)
      })()
    }
  }, [stationName, choosen_station, chartSpan, comparisonMode, stationCompare, yearCompare, shift, startDate, endDate])

  return (
      <Inner>

        <ChartWrapper isLoading={loading}>
          {<GraphTitle>{(data[0] && stationCompare !== null) ? `Jämför ${data[0].tnudatakey['key1-name']} & ${data[0].tnudatakey['key2-name']}` : data[0] ? `Temperatur ${data[0].tnudatarange.range} i ${data[0].tnudatakey['key1-name']}` : 'Temperaturgraf'}</GraphTitle>}
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              data={data.length && data[0].tnudata}
            >
              {data[0] && <XAxis dataKey="timestamp" tickmargin={15} ticks={data[0].ticks.xtickshighres.timestamp} tickFormatter={t => formatXAxis(chartSpan, t)} tick={{fontSize: 12}} interval="preserveStart" />}
              {data[0] && <YAxis domain={[data[0].ticks.ydomain.min, data[0].ticks.ydomain.max]} ticks={data[0].ticks.yticks} allowDecimals={false} tickFormatter={(value, name, props) => { return [`${String(value).replace('.', ',')}°C`] }} type="number" />}
              <Tooltip
                formatter={(value, name, props) => { return [`${String(value).replace('.', ',')}°C`, name === data[0].tnudatakey.key1 ?  data[0].tnudatakey["key1-name"] : data[0].tnudatakey["key2-name"] ] }}
                labelFormatter={val => moment(val * 1000).format('YYYY-MM-DD HH:mm')}
              />
              <CartesianGrid stroke="#e3e3e3" />
              <ReferenceLine y="0" stroke="blue" />

              <Line type="monotone" dot={false} dataKey={data[0] && data[0].tnudatakey.key1} stroke="red" yAxisId={0} />
              <Line type="monotone" dot={false} dataKey={data[0] && data[0].tnudatakey.key2} stroke="green" yAxisId={0} />

            </LineChart>
          </ResponsiveContainer>

          <LegenderWrap>

            {(data[0] && data[0]["amm"]) &&
            <Legender>

              <div>
                <LegendDoot color="red" />
              </div>

              <div>
                <p><small>{(comparisonMode === 'station'  || comparisonMode === 'favorite') && data[0].tnudatakey["key1-name"]}</small></p>
                <p><b>{data[0]["amm"].ammRange.toUpperCase()}</b></p>
                <p><b>Medel: </b>{data[0]["amm"].average.replace('.', ',')}°C</p>
                <p><b>Min: </b>{data[0]["amm"].min.replace('.', ',')}°C <small>{data[0]["amm"].minTime}</small></p>
                <p><b>Max: </b>{data[0]["amm"].max.replace('.', ',')}°C <small>{data[0]["amm"].maxTime}</small></p>
              </div>

          </Legender>}

          {(data[0] && data[0]["amm-history"]) &&
          <Legender>

            <div>
              <LegendDoot color="green" />
            </div>

            <div>
              <p><b>{data[0]["amm-history"].ammRange.toUpperCase()}</b></p>
              <p><b>Medel: </b>{data[0]["amm-history"].average.replace('.', ',')}°C</p>
              <p><b>Min: </b>{data[0]["amm-history"].min.replace('.', ',')}°C <small>{data[0]["amm-history"].minTime}</small></p>
              <p><b>Max: </b>{data[0]["amm-history"].max.replace('.', ',')}°C <small>{data[0]["amm-history"].maxTime}</small></p>
            </div>

        </Legender>}

        {(data[0] && data[0]["amm-compare"]) &&
        <Legender>

          <div>
            <LegendDoot color="green" />
          </div>

          <div>
            <p><small>{(comparisonMode === 'station'  || comparisonMode === 'favorite') && data[0].tnudatakey["key2-name"]}</small></p>
            <p><b>{data[0]["amm-compare"].ammRange.toUpperCase()}</b></p>
            <p><b>Medel: </b>{data[0]["amm-compare"].average.replace('.', ',')}°C</p>
            <p><b>Min: </b>{data[0]["amm-compare"].min.replace('.', ',')}°C <small>{data[0]["amm-compare"].minTime}</small></p>
            <p><b>Max: </b>{data[0]["amm-compare"].max.replace('.', ',')}°C <small>{data[0]["amm-compare"].maxTime}</small></p>
          </div>

        </Legender>}

          </LegenderWrap>

        </ChartWrapper>

      {chartSpan !== null && <ArrowWrapper>
        <Arrow onClick={() => setShift(shift + 1)}><FontAwesomeIcon icon={['fal', 'long-arrow-left']} /></Arrow>
        <Arrow onClick={() => shift !== 0 && setShift(shift - 1)} disabled={shift === 0}><FontAwesomeIcon icon={['fal', 'long-arrow-right']} /></Arrow>
      </ArrowWrapper>}

      <SelectorPositioner>

        <ChoiceSelector
          title=""
          selectedValue={chartSpan}
          onChange={val => {
            setChartSpan(val)
            setShift(0)
          }}
          choices={[
            { name: "Eget tidspann", icon: "stopwatch", value: null },
            { name: "Dygn", icon: "calendar-day", value: "day" },
            { name: "Vecka", icon: "calendar-week", value: "week" },
            { name: "Månad", icon: "calendar-alt", value: "month" },
            { name: "År", icon: "calendar-check", value: "year" },
          ]}
        />

        {chartSpan === null && <DatetimeWrapper>
          <DatetimeSelector inputProps={{ placeholder: 'Startdatum' }} onChange={date => setStartDate(moment(date).unix())} />
          <span>till</span>
          <DatetimeSelector inputProps={{ placeholder: 'Slutdatum' }} onChange={date => setEndDate(moment(date).unix())} />
        </DatetimeWrapper>}

        <ChoiceSelector
          title="Jämför med:"
          selectedValue={comparisonMode}
          onChange={val => {
            setComparisonMode(val)
            setStationCompare(null)
            setYearCompare(null)
          }}
          choices={[
            { name: "Jämför inte", icon: "times", value: "" },
            ((data[0] && getDates(moment(data[0].start), moment())) ? { name: "Annat år", icon: "exchange", value: "year" }: {}),
            (chartSpan !== null ? { name: "Föregående period", icon: "repeat-alt", value: "previous_period" } : {}),
            { name: "Annan mätpunkt", icon: "house-day", value: "station" },
            ((choosen_station && choosen_station !== stationName) ? { name: "Favorit", icon: "star", value: "favorite" } : {})
          ]}
        />

      </SelectorPositioner>

      {(comparisonMode === 'station' && !stationCompare) && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} ><SearchBar onChange={station => setStationCompare(station)} /></div>}
      {(comparisonMode === 'station' && stationCompare) && <small>Jämför med: {data[0].tnudatakey['key2-name'] || ''}</small>}
      {comparisonMode === 'year' &&
      <ChoiceSelector
        title="Välj år att jämföra med:"
        selectedValue={yearCompare}
        onChange={val => {
          setYearCompare(val)
          setStationCompare(null)
        }}
        choices={getDates(moment(data[0].start), moment()).map(year => { return { name: year, icon: "clock", value: year } })}
      />
      }

      </Inner>
  )
}

export default TempChart

const formatXAxis = (span, tickItem) => {
  if (span === "day") {
    return moment(tickItem * 1000).format('HH:mm')
  }
  else {
    return moment(tickItem * 1000).format('YYYY-MM-DD')
  }
}

// helper för att räkna ut alla år mellan två punkter
const getDates = (startDate, stopDate) => {
    var dateArray = []
    var currentDate = startDate
    while (currentDate.format('YY') <= stopDate.format('YY')) {
        dateArray.push(currentDate.format('YYYY'))
        currentDate = currentDate.add(1, 'year')
    }
    dateArray.pop()
    return dateArray
}
