import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TextBlock from '../components/TextBlock'
import ShadowBox from '../components/ShadowBox'
import PositionRetriver from '../containers/PositionRetriver'
import goTo from '../modules/goTo'

import { checkSavedStation } from '../actions/ui'

const LoadingWrapper = styled(ShadowBox)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 0;
  h1 {margin-top: 25px;}
  svg {opacity: 0.6}
`

const NearbyWeatherManager = () => {
  const dispatch = useDispatch()
  const choosen_station = useSelector(state => state.ui.choosen_station)

  // Kolla efter localStorage-sparade val
  useEffect(() =>
    dispatch(checkSavedStation())
  , [dispatch])

  if(choosen_station){goTo(`${process.env.PUBLIC_URL}/${choosen_station}`)}
  return choosen_station
  ? <LoadingWrapper>
    <FontAwesomeIcon size="5x" icon={['fal', 'spinner-third']} spin />
    <TextBlock>
      <h1>Laddar station...</h1>
    </TextBlock>
  </LoadingWrapper>
  : <PositionRetriver />
}

export default NearbyWeatherManager
