import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import moment from 'moment'

import { YR_WEATHER_API_URL, YR_SUNRISE_API_URL } from '../constants/endpoints'
import { setCurrentGeolocation, setChoosenStation, checkSavedStation } from '../actions/ui'

import Title from '../components/Title'
import ShadowBox from '../components/ShadowBox'
import PrognosisWrapper from '../components/PrognosisWrapper'
import PrognosisRow from '../components/PrognosisRow'
import goTo from '../modules/goTo'
import TempChart from '../containers/TempChart'
import { useMediaQuery } from 'react-responsive'


const Outer = styled.div`
  margin: 0;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

`

const WhereTitle = styled.span`
  width: 100%;
  font-size: 1rem;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  opacity: 0.8;
  margin-right: auto;
  > span {
    float: right;
    margin-left:10px;
    text-decoration: underline;
    cursor: pointer;
  }
`

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 20px 15px;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`

const MainTemp = styled.span`
  font-size: 70px;
  font-family: 'Roboto', sans-serif;
  padding: 10px 0;
  opacity: 0.9;
  margin-top: 10px;
  min-height:105px;
  @media only screen and (max-width: 768px) {
    min-height: 77px;
    font-size: 60px;
    flex-wrap: wrap-reverse;
    padding: 5px 0 0 0;
  }

`

const WheatherImage = styled.img`
  width: 160px;

  @media only screen and (max-width: 768px) {
    width: 145px;
    margin-bottom: 10px;
  }

`

const VerticalPrognosisWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 139px; 
  border: 1px solid rgba(0,0,0,0.45);
  box-shadow: 0 4px 3px rgba(0,0,0,0.05), 0 -1px 3px rgba(0,0,0,0.06);
  border-radius: 12px;

  > div{
    margin: 0;
    border-bottom: 1px solid rgba(0,0,0,0.45);
    border-radius: 0;
    &:last-child{border: none;}
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: auto;
  }

`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #f5f5f6;
  z-index: 10;
  font-size: 100px;
  opacity: 1;
  color: grey;
  border-radius: 12px;

`

const Attrib = styled.small`
  opacity: 0.6;
  font-size: 14px;
  font-family: 'Averia Serif Libre', cursive;
  text-align: center;
`

const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  border-radius: 6px;
  color: #fff;
  background: #d63031;
  margin-bottom: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3), -1px -1px 2px rgba(0, 0, 0, 0.2);

  font-size: 14px;
  font-family: 'Averia Serif Libre', cursive;
  text-align: center;
`

const Nearbys = styled.div`
  display: flex;
  margin: 15px 0 0 0;
  > div{
    width: 100%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid transparent;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.05);
    h5{
      font-size: 18px;
      font-family: 'Averia Serif Libre', cursive;
      margin: 5px auto 0 0;
    }
    p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 0;
    }
    small{
      opacity: 0.75;
    }
    &:hover{
      border: 1px solid;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

`
const NearbyTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
  > span {
    font-size: 18px;
    margin-bottom: -10px
    }
`


const Station = ({ id, lat, lon, title, temp, felmeddelande, lastUpdate, sourceInfo, nearby = [] }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  dispatch(checkSavedStation())
  const choosen_station = useSelector(state => state.ui.choosen_station)

  const [loading, setLoading] = useState(true)

  const viewMobile = useMediaQuery({ query: '(max-device-width: 768px)' })
  const [currentPrognosis, setCurrentPrognosis] = useState([])
  const [twenthyFourHourPrognosis, setTwenthyFourHourPrognosis] = useState([])
  const [threeDayPrognosis, setThreeDayPrognosis] = useState([])
  const [tenDayPrognosis, setTenDayPrognosis] = useState([])

  useEffect(() => {
    if(lat && lon) {
      (async () => {
        const response = await axios({
           url: YR_WEATHER_API_URL,
           params: {lat: lat, lon: lon, altitude: 1},
           timeout: 5000
         })

         setCurrentPrognosis(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment()))[0])

         setTwenthyFourHourPrognosis([
           response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().add(1,'hours')))[0],
           response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().add(7,'hours')))[0],
           response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().add(13,'hours')))[0],
           response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().add(19,'hours')))[0]
         ])

         const threeDayPrognosisTemp = [
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(0).minute(0).add(1,'days')))[1]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(0).minute(0).add(2,'days')))[1]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(0).minute(0).add(3,'days')))[1])
         ]

         const tenDayPrognosisTemp = [
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(1,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(2,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(3,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(4,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(5,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(6,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(7,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(12).add(8,'days')))[0]),
           remapRow(response.data.properties.timeseries.filter(t => moment(t.time).isSameOrAfter(moment().hour(6).add(9,'days')))[0])
         ].filter(i => Object.keys(i) !== 0)

         const sunsetResponse = await axios({
            url: YR_SUNRISE_API_URL,
            params: {
              date: moment().format("YYYY-MM-DD"),
              days: 15,
              lat: lat,
              lon: lon,
              offset: moment().format('Z')
            },
            timeout: 5000
          })

          const remappedSunData = sunsetResponse.data.location.time.reduce((acc, { date, sunrise, sunset }) => {
            if(!sunrise || !sunset){ return acc }
            return { ...acc, [date]: { sunrise: sunrise.time, sunset: sunset.time } }
          }, {})

          /*
          setThreeDayPrognosis(threeDayPrognosisTemp.map(prog => {
            return {
              ...prog,
              sunrise: remappedSunData[moment(prog.time).format('YYYY-MM-DD')].sunrise,
              sunset: remappedSunData[moment(prog.time).format('YYYY-MM-DD')].sunset,
            }
          }))
          */

          setTenDayPrognosis(tenDayPrognosisTemp.map(prog => {
            return {
              ...prog,
              sunrise: remappedSunData[moment(prog.time).format('YYYY-MM-DD')].sunrise,
              sunset: remappedSunData[moment(prog.time).format('YYYY-MM-DD')].sunset,
            }
          }))

         setLoading(false)

      })()
    }
  }, [lat, lon]) // eslint-disable-line

  return (
    <Outer>
      <ShadowBox>
      {loading && <LoadingWrapper><FontAwesomeIcon icon={['fal', 'spinner-third']} spin /></LoadingWrapper>}
        <Inner style={{ zIndex: 1 }}>
          <WhereTitle>
          Temperaturen just nu (uppmätt {lastUpdate}) 


	  <span onClick={() => {
            if(choosen_station === id){
              dispatch(setCurrentGeolocation(null))
              dispatch(setChoosenStation(null))
              history.push("/")
            } else {
              dispatch(setChoosenStation(id))
            }
          }}>
	  {choosen_station === id && 
		  <div>
		  <FontAwesomeIcon icon={['fal', 'star-exclamation']} />
		   Byt favorit
		  </div>
	  }
	  {choosen_station !== id &&
                  <div>
                  <FontAwesomeIcon icon={['fal', 'star']} />
                  Sätt som favorit
                  </div>
          }


	  </span>
          
	  <span onClick={() => { goTo(`${process.env.PUBLIC_URL}/${id}`)}}>
                  <FontAwesomeIcon icon={['fal', 'redo']} /> Uppdatera</span>


	  </WhereTitle>

          <MainWrapper>

            <MainTemp>{temp}°C</MainTemp>
            {felmeddelande !== 'Ok' &&
              <Error>
                <FontAwesomeIcon size={'3x'} icon={['fal', 'exclamation-circle']} />
                <p>{felmeddelande}</p>
              </Error>
            }
            {!loading && <WheatherImage src={`${process.env.PUBLIC_URL}/weather/${currentPrognosis.data.next_1_hours.summary.symbol_code}.svg`} height={`${viewMobile ? "145" : "160"}`} />}

            <VerticalPrognosisWrap>
              {!loading && <PrognosisRow
                standing
                title={"Prognos"}
                date={`${moment(currentPrognosis.time).format('HH:mm')} - ${moment(currentPrognosis.time).add(1,'hours').format('HH:mm')}`}
                symbol={""}
                temp={currentPrognosis.data.instant.details.air_temperature.toString().replace('.', ',')}
                precipitation={currentPrognosis.data.next_1_hours.details.precipitation_amount.toString().replace('.', ',')}
                wind={currentPrognosis.data.instant.details.wind_speed.toString().replace('.', ',')}
              />}
            </VerticalPrognosisWrap>

          </MainWrapper>

          <TempChart stationName={id} />

          <PrognosisWrapper title="24h prognos" storageString="24h">
              {!loading && twenthyFourHourPrognosis.map((data, index) =>
                <PrognosisRow
                  key={index}
                  title={`${moment(data.time).format('HH:mm')} - ${moment(data.time).add(6, 'hours').format('HH:mm')}`}
                  date={moment(data.time).format('DD MMMM')}
                  symbol={data.data.next_12_hours ?  data.data.next_12_hours.summary.symbol_code : data?.data?.next_6_hours?.summary?.symbol_code}
                  temp={data.data.instant.details.air_temperature.toString().replace('.', ',')}
                  precipitation={data.data.next_6_hours.details && data.data.next_6_hours.details.precipitation_amount.toString().replace('.', ',')}
                  wind={data.data.instant.details.wind_speed.toString().replace('.', ',')}
                />
              )}
            </PrognosisWrapper>

          {/* <PrognosisWrapper title="3-dygnsprognos" storageString="3day">
            {!loading && threeDayPrognosis.map((data, index) =>
              <PrognosisRow
                key={index}
                title={moment(data.time).format('dddd')}
                date={moment(data.time).format('DD MMMM')}
                symbol={data.symbol}
                temp={data.temp}
                precipitation={data.precipitation}
                wind={data.wind}
                sunrise={data.sunrise}
                sunset={data.sunset}
              />
            )}
            </PrognosisWrapper> */}

          <PrognosisWrapper title="10-dygns prognos" storageString="10day" defaultHidden={true}>
              {!loading && tenDayPrognosis.map((data, index) =>
                <PrognosisRow
                  key={index}
                  title={moment(data.time).format('dddd')}
                  date={moment(data.time).format('DD MMMM')}
                  symbol={data.symbol}
                  temp={data.temp}
                  precipitation={data.precipitation}
                  wind={data.wind}
                  sunrise={data.sunrise}
                  sunset={data.sunset}
                />
              )}
          </PrognosisWrapper>

          <Attrib>
            {!loading && sourceInfo}
            <br />
            Prognosdata och ikoner från YR.no
          </Attrib>

        </Inner>
      </ShadowBox>
      <NearbyTitle><Title>Närliggande stationer</Title></NearbyTitle>
      <Nearbys>
        {!loading && nearby.map(({ id, title, dist, temp }) => 
          <ShadowBox key={id} style={{ cursor: 'pointer' }} onClick={() => goTo(`${process.env.PUBLIC_URL}/${id}`)}>
            <h5>{title}</h5>
            <p>
              <small>{dist} km</small>
              <small>{temp.replace('.', ',')}°C</small>
            </p>
          </ShadowBox>
        )}
      </Nearbys>
    </Outer>
  )
}

export default Station


const remapRow = (data) => {
  if(!data?.time){return {}}
  const {time, data: { instant, next_1_hours, next_6_hours, next_12_hours }} = data

  let remapping = {
    time: time,
    symbol: '',
    temp: null,
    humidity: null,
    precipitation: null,
    wind: null
  }

  if(instant?.details?.air_temperature !== null){
    remapping.temp = instant.details.air_temperature.toString().replace('.', ',')
  }

  if(instant?.details?.relative_humidity){
    remapping.humidity = instant.details.relative_humidity.toString().replace('.', ',')
  }

  if(instant?.details?.wind_speed){
    remapping.wind = instant.details.wind_speed.toString().replace('.', ',')
  }

  if (next_1_hours?.summary?.symbol_code) {
    remapping.symbol = next_1_hours.summary.symbol_code
  } else if (next_6_hours?.summary?.symbol_code) {
    remapping.symbol = next_6_hours.summary.symbol_code
  } else if (next_12_hours?.summary?.symbol_code){
    remapping.symbol = next_12_hours.summary.symbol_code
  }

  if (next_1_hours?.details?.precipitation_amount || next_1_hours?.details?.precipitation_amount === 0) {
    remapping.precipitation = next_1_hours.details.precipitation_amount.toString().replace('.', ',')
  } else if (next_6_hours?.details?.precipitation_amount || next_6_hours?.details?.precipitation_amount === 0) {
    remapping.precipitation = next_6_hours.details.precipitation_amount.toString().replace('.', ',')
  } else if (next_12_hours?.details?.precipitation_amount || next_12_hours?.details?.precipitation_amount === 0){
    remapping.precipitation = next_12_hours.details.precipitation_amount.toString().replace('.', ',')
  }

  return remapping
}
