import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from "react-router-dom"
import styled from 'styled-components'
import axios from 'axios'

import { SINGLE_STATION_URL } from '../constants/endpoints'

import Title from '../components/Title'
import Station from '../containers/Station'
import EmbedStation from '../components/EmbedStation'
import StationInformation from '../containers/StationInformation'
import HorizontalAd from '../containers/ads/HorizontalAd'

const DistText = styled.p`
  font-size: 20px;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  text-align: center;
  margin: 0 0 15px 0;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: -10px;
  }

`

const StationWrapper = ({ stationName, dist }) => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const [stationData, setstationData] = useState({})

  useEffect(() => {
    if(stationName){
      (async () => {
        setLoading(true)
        try{
        const response = await axios({
           url: SINGLE_STATION_URL,
           params: {
             p: stationName,
             num: 3
           },
           timeout: 5000
         })

         setstationData(response.data.stations[Object.keys(response.data.stations)[0]])
         setLoading(false)
       } catch (e) {
         history.push("/")
       }

      })()
    }
  }, [stationName, history])

  return (
    <>

      {!loading && <Helmet>
        <title>{stationData.title ? stationData.title : stationName} | Temperatur.nu</title>
        <meta name="description" content={`Temperatur och v&auml;der det senaste dygnet i ${stationData.kommun} - ${stationData.title}`} / >
        <meta name="keywords" content={`Temperatur, V&auml;der, prognos, v&auml;derprognos, aktuell temperatur, ${stationData.kommun} - ${stationData.title}`}  />
        <meta property="og:title" content={`Temperatur och v&auml;der i ${stationData.kommun} - ${stationData.title}`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.temperatur.nu/logo.png" />
        <meta property="og:url" content="https://www.temperatur.nu" />
        <meta property="og:locality" content={stationData.kommun} />
        <meta property="og:country-name" content="Sweden" />
        <meta property="og:latitude" content={stationData.lat} />
        <meta property="og:longitude" content={stationData.lon} />
      </Helmet>}

      <HorizontalAd type="custom0" />

      <Title page>{stationData.title ? stationData.title : stationName}</Title>

      {dist && <DistText>{dist}km från dig</DistText>}

      <Station {...stationData} stationChangeable />

      <HorizontalAd type="custom1" />

      {!loading && <StationInformation {...stationData} />}

      <HorizontalAd type="custom2" />

      {!loading && <EmbedStation stationName={stationName} />}

    </>
  )
}

export default StationWrapper
