import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom'

import ShadowBox from '../components/ShadowBox'

const Wrapper = styled.div`
  display: flex;
  flex-direction: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

`

const MenuWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`

const MenuLink = styled.a`
  position: relative;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;

  font-size: 22px;
  margin: 0 15px;
  text-decoration: none;
  opacity: 0.8;
  margin-right: auto;

  &:hover, &.active{opacity: 1;}
  &:hover::after,
  &.active::after {
    content: '';
    position: absolute;
    bottom: 0.25rem;
    left: 0.05rem;
    right: 0;
    display: block;
    height: 10px;
    width: 100%;
    background: #f9ca24;
    z-index: -10;
    transform: rotate(-1deg);
  }

`

const RightWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin: 25px 0 10px 0;
  }

`

const Logo = styled.img`
  width: 100px;
`

const Copyright = styled.a`
  font-size: 14px;
  text-decoration: none;
  font-family: 'Averia Serif Libre', cursive;
  margin-top: 15px;
  &:hover{text-decoration: underline;}
`

const Footer = () => {
  return (
    <ShadowBox style={{width: "100%", marginTop: 25}}>
      <Wrapper>

        <MenuWrap>
          <MenuLink href="https://www.temperatur.nu/info/kontakt/">Kontakt</MenuLink>
          <MenuLink href="https://www.temperatur.nu/info/sa-har-fungerar-temperatur-nu/">Om Temperatur.nu</MenuLink>
          <MenuLink href="https://www.temperatur.nu/info/api/">API</MenuLink>
          <MenuLink href="https://www.temperatur.nu/info/cookies-pa-temperatur-nu/">Cookies</MenuLink>
          <MenuLink href="https://www.temperatur.nu/info/rapportera-till-temperatur-nu/">Rapportera din temperatur</MenuLink>
          <MenuLink href="https://www.temperatur.nu/egenadmin/">Hantera mätstation</MenuLink>
	  <MenuLink href="https://forum.m.nu">Forum</MenuLink>
	  <MenuLink href="https://www.temperatur.nu/info/driftstatus/">Driftstatus</MenuLink>
        </MenuWrap>

        <RightWrap>
          <Copyright href="https://www.temperatur.nu/info/logotyper/"><Logo src={`${process.env.PUBLIC_URL}/icon.png`} width="100" height="100" /></Copyright>
          <Copyright  href="https://www.temperatur.nu/info/upphovsratt/">© 2001-{moment().year()} Erik Freiholtz</Copyright>
        </RightWrap>

      </Wrapper>
    </ShadowBox>
  )
}
export default Footer
