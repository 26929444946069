import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'

import { NavLink } from 'react-router-dom'

const Wrapper = styled.div`
  padding-top: 3%;
`

const MenuLink = styled.a`
  position: relative;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;

  font-size: 20px;
  margin: 0 8px;
  text-decoration: none;
  opacity: 0.8;

  &:hover, &.active{opacity: 1;}
  &:hover::after,
  &.active::after {
    content: '';
    position: absolute;
    bottom: 0.25rem;
    left: 0.05rem;
    right: 0;
    display: block;
    height: 10px;
    width: 100%;
    background: #f9ca24;
    z-index: -10;
    transform: rotate(-1deg);
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }

`

const MenuLinkMobile = styled.a`
  display: block;
  font-size: 32px;
  margin: 5px 10px;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  text-decoration: none;
`

const Bars = styled(FontAwesomeIcon)`
  display: none;
  font-size: 30px;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const Close = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 40px;
  opacity: 0.4;

  &:hover{
    opacity: 1;
  }

`

const SlideoutMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 90vw;
  z-index: 100;
  background: #f5f5f6;
  border-radius: 25px 0 0 25px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  padding-top: 25%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  transform: translateX(120%);
  transition: 0.3s ease;
  ${({ open }) => open && 'transform: translateX(0%);'}

`

const Menu = () => {
  const choosen_station = useSelector(state => state.ui.choosen_station)
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Wrapper>
      <MenuLink href={`${process.env.PUBLIC_URL}/narmaste`}>Närmaste</MenuLink>
      <MenuLink exact href={choosen_station ? `${process.env.PUBLIC_URL}/${choosen_station}` : `${process.env.PUBLIC_URL}/`}>Favorit</MenuLink>
      <MenuLink href={`${process.env.PUBLIC_URL}/matplatser`}>Alla mätstationer</MenuLink>
      <MenuLink href={`${process.env.PUBLIC_URL}/karta`}>Karta</MenuLink>

      <Bars onClick={() => setMenuOpen(true)} icon={['fal', 'bars']} />

      <SlideoutMenu open={menuOpen}>
        <Close onClick={() => setMenuOpen(false)} icon={['fal', 'times-circle']} />
        <MenuLinkMobile href={`${process.env.PUBLIC_URL}/narmaste`}>Närmaste</MenuLinkMobile>
        <MenuLinkMobile exact href={choosen_station ? `${process.env.PUBLIC_URL}/${choosen_station}` : `${process.env.PUBLIC_URL}/`}>Favorit</MenuLinkMobile>
        <MenuLinkMobile href={`${process.env.PUBLIC_URL}/matplatser`}>Alla mätstationer</MenuLinkMobile>
        <MenuLinkMobile href={`${process.env.PUBLIC_URL}/karta`}>Karta</MenuLinkMobile>

      </SlideoutMenu>

    </Wrapper>
  )
}
export default Menu
