import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { useDispatch } from 'react-redux'

const Outer = styled.div`
  width: 65px;
  height: 65px;
  padding: 12px 0 5px 0;
  background: rgb(240, 238, 235);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  margin: 2px;
  > svg {
    font-size: 21px;
    margin-bottom: 6px;
  }

  @media only screen and (max-width: 768px) {
    flex-grow: 1;
    flex-basis: 1;
    width: auto;
    height: 65px;
    font-size: 11px;
    > svg {
      font-size: 18px;
    } 
  }

`

const IndicatorBox = ({ children, icon }) =>
    <Outer><FontAwesomeIcon icon={["fal", icon]} />{children}</Outer>


export default IndicatorBox
