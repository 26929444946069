import React from 'react'
import styled from 'styled-components'

import Title from '../components/Title'
import TextBlock from '../components/TextBlock'
import ShadowBox from '../components/ShadowBox'

const Inner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
`

const ScriptHolder = styled.textarea`
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  resize: none;
  background: rgb(240,238,235);
  border: 1px solid;
  border-radius: 5px;
`


const StationInformation = ({ stationName }) => {

  return (
    <ShadowBox style={{ width: '100%' }}>
      <Inner>

      <Title>Visa på hemsida</Title>

      <TextBlock>
        <h2>Villkor för att hämta och visa temperaturer från temperatur.nu</h2>
        <ul>
          <li>Länkarna är tänkta att användas för att visa temperaturer på tex en hemsida eller i någon annan enkel tillämpning. För mer avancerade tillämpningar rekommenderar vi <a href={`/info/API`}>att du använder vårt API</a></li>
          <li>Visar du temperaturen på en hemsida ska temperaturen vara en klickbar länk som pekar på  www.temperatur.nu/{stationName}</li>
          <li>Vi är mycket tacksama om du skriver att temperaturerna kommer från temperatur.nu i anslutning till där du visar dem.</li>
        </ul>
        <br/>
        <h2>Visa temperaturen på Din hemsida:</h2>
      </TextBlock>

      <ScriptHolder disabled value={`<!-- Temperatur från temperatur.nu --><script type="text/javascript" src="https://www.temperatur.nu/jstemp.php?s=${stationName}"></script>`} />

        <TextBlock style={{ marginTop: 15 }}>
          <h2>Skriv en egen lösning för att hämta temperaturen</h2>
          För den som vill programmera egna lösningar finns aktuell temperatur på följande adress:
          <a target="_blank" rel="noreferrer" href={`http://www.temperatur.nu/termo/${stationName}/temp.txt`}>http://www.temperatur.nu/termo/{stationName}/temp.txt</a>
        </TextBlock>

      </Inner>
    </ShadowBox>
  )
}

export default StationInformation
