import React from 'react'
import styled from 'styled-components'

import ShadowBox from '../../components/ShadowBox'

const Track = styled.div`
  margin: 0 ${({ right }) => right ? "auto" : "20px"} 0 ${({ left }) => left ? "auto" : "20px"};
  @media only screen and (max-width: 1300px) {
    display: none;
  }
`

const Positioner = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding-top: 2rem;
`

const Placeholder = styled.div`
  height: 600px;
  width: 160px;
  background: lightgrey;
`

const VerticalDesktopAd = ({ left, right }) => {

  return (
    <Track left={left} right={right}>
      <Positioner>
        <ShadowBox small style={{ minWidth: "160px", minHeight: "600px" }}>
          <div className="holidAds tower"></div>
        </ShadowBox>
      </Positioner>
    </Track>
  )
}

export default VerticalDesktopAd