import React from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import styled from 'styled-components'
import { Page } from '../style/wrappers'

import { MAP_URL } from '../constants/endpoints'

import Header from '../containers/Header'
import VerticalDesktopAd from '../containers/ads/VerticalDesktopAd'
import HorizontalAd from '../containers/ads/HorizontalAd'
import Footer from '../components/Footer'

const OuterPage = styled.div`
  display: flex;
`

const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5% 0;
`

const Map = ({ location }) => {
  const params = queryString.parse(location.search)

  return (
    <OuterPage>
      <VerticalDesktopAd left />
      <Page>
        <Helmet>
          <title>Kartan | Temperatur.nu</title>
        </Helmet>
        <Outer>
          <Header />
          <HorizontalAd type="custom0" />
          <iframe allowFullScreen style={{ height: 520, border: 0 }} title="kartan" src={`${MAP_URL}#${params.lat ? String(params.lat).replace(',', '.') : 62.37},${params.lng ? String(params.lng).replace(',', '.') : 15.62448},${params.z || 4.5}z&alias=${params.p}`}></iframe>
          <HorizontalAd type="custom2" />
          <Footer />
        </Outer>
      </Page>
      <VerticalDesktopAd right />
    </OuterPage>
  )
}

export default Map
