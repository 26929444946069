import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider as ReduxProvider } from 'react-redux'
import thunk from 'redux-thunk'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faTimesCircle,
  faRedo,
  faExclamationCircle,
  faSpinnerThird,
  faLongArrowRight,
  faLongArrowLeft,
  faThermometerHalf,
  faUmbrella,
  faWind,
  faSunrise,
  faSunset,
  faStopwatch,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faCalendarCheck,
  faTimes,
  faExchange,
  faRepeatAlt,
  faHouseDay,
  faHourglass,
  faSortCircle,
  faStar,
  faFontCase,
  faTemperatureLow,
  faCompass,
  faSortSizeUpAlt,
  faSortSizeDownAlt,
  faStarExclamation,
} from '@fortawesome/pro-light-svg-icons'
import { ThemeProvider } from 'styled-components'

import 'moment/locale/sv'
import moment from 'moment'

import themeLight from './style/theme_light'
import GlobalStyles from './style/GlobalStyles'
import reducer from './reducers/index'
import * as serviceWorker from './serviceWorker'

import Router from './Router'

library.add(
  faBars,
  faTimesCircle,
  faRedo,
  faExclamationCircle,
  faSpinnerThird,
  faLongArrowRight,
  faLongArrowLeft,
  faThermometerHalf,
  faUmbrella,
  faWind,
  faSunrise,
  faSunset,
  faStopwatch,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faCalendarCheck,
  faTimes,
  faExchange,
  faRepeatAlt,
  faHouseDay,
  faHourglass,
  faSortCircle,
  faStar,
  faFontCase,
  faTemperatureLow,
  faCompass,
  faSortSizeUpAlt,
  faSortSizeDownAlt,
  faStarExclamation,
)
moment.locale('sv')

const store = createStore(
  reducer,
  compose(applyMiddleware(thunk))
)
window.store = store

render(
  <ReduxProvider store={store}>
  <ThemeProvider theme={themeLight}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
)

serviceWorker.register()
