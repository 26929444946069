const themeLight = {
  type: 'light',
  primary_body: '#fff',
  secondary_body: '#f0eeeb',
  divider: '#dedad7',
  primary_text: '#171717',
  secondary_text: '#787573',
  background: '#f5f5f6',
  primary_font: '"Raleway", sans-serif',
  secondary_font: '',
  primary_button_text: '#fff',
  primay_button_background: '#171717',
  good: '#00b894',
  bad: '#e17055',
  headsup: '#ff9f43'
}

export default themeLight
