import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import Title from '../components/Title'
import TextBlock from '../components/TextBlock'
import ShadowBox from '../components/ShadowBox'
import Button from '../components/Button'
import ChoiceSelector from '../components/ChoiceSelector'

import { SINGLE_STATION_URL, RECAPTCHA_KEY } from '../constants/endpoints'

const Inner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InfoBox = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0;

  > div{
    display: flex;
    flex-direction: column;
    min-width: 40%;
    margin: 0 10px;

    &:last-child{
      font-size: 16px;
      background: rgb(240,238,235);
      padding: 15px;
      border: 1px solid;
      border-radius: 5px;
    }

  }

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: auto;
    margin: 2px 0;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

`

const SubmitWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 25px 0;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    > button{
      margin-top: 15px;
    }
  }


`

const StationInformation = ({ id, title, lat, lon, start, moh, uptime, sourceInfo, forutsattning, kommun, lan }) => {
  const [downloadSpan, setDownloadSpan] = useState(null)
  const [downloadResult, setDownloadResult] = useState(null)

  const getDownloadData = async () => {
    const response = await axios({
       url: SINGLE_STATION_URL,
       params: {
         p: id,
         textfil: downloadSpan
       },
       timeout: 5000
     })

     setDownloadResult(response.data.stations[Object.keys(response.data.stations)[0]])

  }

  return (
    <ShadowBox>
      <Inner>

      <Title>Information om stationen</Title>

      <InfoBox>
        <div style={{ width: '100%' }}>
          <TextBlock>
            <p style={{marginTop: 0}}>{sourceInfo}</p>
            <h2>förutsättningar</h2>
            <p>{forutsattning}</p>
          </TextBlock>
        </div>

        <div>
          <span><b>Kommun:</b> {kommun}</span>
          <span><b>Län:</b> {lan}</span>
          <span><b>Uptime:</b> {uptime}%</span>
          <span><b>Meter över havet:</b> {moh}m</span>
          <span><b>Start:</b> {start}</span>
          <Button as="a" target="_blank" href={`${process.env.PUBLIC_URL}/karta?lat=${lat}&lng=${lon}&z=11&p=${id}`} style={{ marginTop: 15 }}>Se på kartan</Button>
        </div>
      </InfoBox>

      <Title>Generera textfil</Title>

      <InfoBox>
        <div style={{flexBasis: '1'}}>
          <TextBlock>
            <p>Använder du denna funktion får du en csv-fil med alla tillgängliga data för {title} enligt dina val nedan. </p>
          </TextBlock>

          {downloadResult && <Button as="a"  href={downloadResult.textfil}>Ladda ner textfil</Button>}

          {!downloadResult  && <ChoiceSelector
          title="Tidsspan"
          selectedValue={downloadSpan}
          onChange={val => setDownloadSpan(val)} choices={[
            { name: "Dygnsmedel", icon: "calendar-day", value: "dygnsmedel" },
            { name: "Timmedel", icon: "hourglass", value: "timmedel" },
            { name: "Graddagar", icon: "sort-circle", value: "graddagar" },
          ]} />}

        </div>
        <div>
          <TextBlock>
          <b>Nedladdning av temperaturdata i textformat är gratis när informationen används privat, i studier samt i forskningssyfte.</b>
            <br />
            <br />
            Om temperaturerna användas i ett <b>företags verksamhet</b> är avgiften 495kr ink moms. Skicka ett mail till <a href="mailto:erik@freiholtz.com">erik@freiholtz.com</a> med fakturauppgifter.
          </TextBlock>
        </div>
      </InfoBox>

      {!downloadResult && <Button style={{ opacity: downloadSpan ? 1 : 0.2 }} onClick={() => downloadSpan && getDownloadData()}>Skapa textfil</Button>}

      </Inner>
    </ShadowBox>
  )
}

export default StationInformation
