import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import ShadowBox from '../../components/ShadowBox'

const Positioner = styled.div`
  margin: 15px 0;
`

const Placeholder = styled.div`
  height: 100px;
  width: 100%;
  background: lightgrey;
`

const HorizontalAd = ({ type }) => {
  const shouldUseSquareAd = useMediaQuery({ query: '(max-device-width: 768px)' })

  return (
    <Positioner>
      <ShadowBox small style={{ minHeight: "120px" }}>
        <div className={`holidAds ${shouldUseSquareAd ? "box" : type}`}></div>
      </ShadowBox>
    </Positioner>
  )
}

export default HorizontalAd
