import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ShadowBox from '../components/ShadowBox'

const Inner = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 12px;
  border: 1px solid transparent;
  &:hover{
    border: 1px solid;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  overflow: hidden;
  font-size: 22px;
  font-family: 'Averia Serif Libre', cursive;
  text-overflow: ellipsis;
  margin: 0;
  > span {
    font-size: 20px;
    margin: 0;
    margin-bottom: px;
  }
  > small {
    font-size: 14px;
    opacity: 0.6;
  }
`

const Value = styled.span`
  width: 30;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
`

const StationRow = ({ title, kommun, value, id }) => {

  return (
    <div>
      <ShadowBox small style={{ padding: "0", margin: "4px 5px" }}>
        <Inner  href={`${process.env.PUBLIC_URL}/${id}`}>
          <Title>
            <span>{title}</span>
            <small>{kommun}</small>
          </Title>
          <Value>{value}°C</Value>
        </Inner>
      </ShadowBox>
    </div>
  )
}

export default StationRow
