import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-size: 16px;
  margin: 0;
  text-overflow: wrap;
  h1, h2 {
    opacity: 0.8;
    font-size: 17px;
    font-family: 'Averia Serif Libre', cursive;
    text-decoration: underline;
    margin: 5px 0;
  }
  h1 {
    font-size: 24px;
    text-decoration: none;
  }
  a {
    text-overflow: wrap;
    word-wrap: break-word;
  }
`


const TextBlock = ({ children, ...other }) => {
  return (
    <Wrapper {...other}>
      {children}
    </Wrapper>
  )
}
export default TextBlock
