import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Page } from '../style/wrappers'

import Header from '../containers/Header'
import VerticalDesktopAd from '../containers/ads/VerticalDesktopAd'
import StationWrapper from '../containers/StationWrapper'

import Footer from '../components/Footer'

const OuterPage = styled.div`
  display: flex;
`

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5% 0;
`

const SingleStation = ({ match }) => {
  return (
    <OuterPage>
      <VerticalDesktopAd left />
      <Page>
        <Helmet>
          <title>Station | Temperatur.nu</title>
        </Helmet>
        <Outer>
          <Header />
          <StationWrapper stationName={match.params.name} />
          <Footer />
        </Outer>
      </Page>
      <VerticalDesktopAd right />
    </OuterPage>
  )
}

export default SingleStation
