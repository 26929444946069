import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Page } from '../style/wrappers'

import seoText from '../constants/seoText'

import Header from '../containers/Header'
import VerticalDesktopAd from '../containers/ads/VerticalDesktopAd'
import HorizontalAd from '../containers/ads/HorizontalAd'
import NearbyWeatherManager from '../containers/NearbyWeatherManager'

import Title from '../components/Title'
import TextBlock from '../components/TextBlock'
import ShadowBox from '../components/ShadowBox'
import Footer from '../components/Footer'

const OuterPage = styled.div`
  display: flex;
`

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5% 0;
`

const SeoText = styled.div`
  width: 100%;
  padding: 25px 10px;
`

const Home = () => {
  return (
    <OuterPage>
      <VerticalDesktopAd left />
      <Page>
        <Helmet>
          <title>Närmaste mätpunkt | Temperatur.nu</title>
        </Helmet>
        <Outer>
          <Header />
          <Title page></Title>
          <NearbyWeatherManager />
          <HorizontalAd type="custom0" />
          <ShadowBox style={{ marginBottom: 15 }}>
            <SeoText>
              <TextBlock dangerouslySetInnerHTML={{__html: seoText}} />
            </SeoText>
          </ShadowBox>
          <Footer />
        </Outer>
      </Page>
      <VerticalDesktopAd right />
    </OuterPage>
  )
}

export default Home
