
// Funkar bra med rå html kod här!
const seoText = `
    <h1>Temperatur.nu samlar in och presenterar temperaturer i realtid från hela Sverige.</h1>
    <p>De temperaturvärden som presenteras på sidan är uppmätta temperaturer och mätpunkterna uppdateras med några minuters mellanrum. Om du undrar hur temperatur.nu fungerar finns det en beskrivning av det <a href="https://www.temperatur.nu/info/sa-har-fungerar-temperatur-nu/">här</a>.</p>
    <p>Det är lätt att rapportera din temperatur till temperatur.nu – och det går att rapportera temperaturen på flera olika sätt – läs mer om hur rapportering går till <a href="https://www.temperatur.nu/info/rapportera-till-temperatur-nu/">här</a>.</p>
    <p>De flesta som rapporterar temperaturer är privatpersoner, men även skolor, kommuner och företag bidrar med temperaturdata. Temperaturer hämtas även från Trafikverkets öppna API.</p>
    <p>Om du är nyfiken på hur sidan startades kan du läsa mer om storyn bakom temperatur.nu <a href="https://temperatur.nu/info/storyn-bakom-temperatur-nu/">här</a>.</p>
`

export default seoText