import React from 'react'
import styled from 'styled-components'

const TitleSpan = styled.span`
  width: 100%;
  font-size: ${({ page }) => page ? 46 : 26}px;
  min-height: 55px;
  opacity: 0.85;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: ${({ page }) => page ? "5px 0 5px 0" : "10px 0 10px 0"};

  @media only screen and (max-width: 768px) {
    min-heigt: 26px;
    font-size: ${({ page }) => page ? 6 : 4}vw;
    margin: ${({ page }) => page ? 15 : 5}px 0;
  }

`


const Title = ({ children, page = false }) => {

  return (
    <TitleSpan page={page}>{children}</TitleSpan>
  )
}
export default Title
