// Här ändras endpointsen för alla utgående förfrågningar i frontenden.
// Användbart att ändra vid version bumps, api ändringar etc.
// notera att variabla querystrings injectas när förfrågan skickas, så lägg inte till t.e.x lat, lng, stationname här.

export const SINGLE_STATION_URL = `https://api.temperatur.nu/tnu_1.18.php?cli=tnu&verbose&dc&nearby`
export const STATION_LIST_URL = `https://api.temperatur.nu/tnu_1.18.php?cli=tnu&dc`
export const GRAPH_DATA_URL = `https://api.temperatur.nu/tnu_1.18.php?cli=tnu&tnudata&verbose&amm`
export const GET_FROM_COORDS_URL = `https://api.temperatur.nu/tnu_1.18.php?cli=tnu`

export const SEARCH_URL = `https://api.temperatur.nu/tnu_1.18.php?cli=tnu&by=rising&sort=alfabetiskt`
export const MAP_URL = `https://www.temperatur.nu/karta2.php`

export const YR_WEATHER_API_URL = 'https://api.temperatur.nu/yr.php'
export const YR_SUNRISE_API_URL = 'https://api.temperatur.nu/sun.php'

export const RECAPTCHA_KEY = '6LfqshEUAAAAAJh85raOoNs2EvsACttNk-0bvZ5z'

// För holid.js scriptet, byt till false om Holid moderniserar sig
export const USE_REFRESH_FOR_PAGE_CHANGE = true
