import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { useDispatch } from 'react-redux'

const Outer = styled.div`
  min-height: 30px;
  width: 100%;
  padding: 5px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: auto;

  border: 1px solid rgba(0,0,0,0.45);
  box-shadow: 0 4px 3px rgba(0,0,0,0.05), 0 -1px 3px rgba(0,0,0,0.06);
  border-radius: 12px;
  margin: 15px 0;

  > div{
    margin: 0;
    border-bottom: 1px solid rgba(0,0,0,0.45);
    border-radius: 0;
    &:last-child{border: none;}
  }

`

const PrognosisTitle = styled.p`
  position: absolute;
  top: -21px;
  left: 10px;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 10px 1px 10px;
  margin: 5px 0;
  border: 1px solid rgba(0,0,0,0.45);
  border-radius: 5px;
  background: #f5f5f6;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    top: -20px;
    font-size: 13px;
  }

`

const PrognosisToggle = styled.p`
  position: absolute;
  top: -21px;
  right: 10px;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 10px 1px 10px;
  margin: 5px 0;
  border: 1px solid rgba(0,0,0,0.45);
  border-radius: 5px;
  background: #f5f5f6;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    top: -20px;
    font-size: 13px;
  }

`

const PrognosisWrapper = ({ children, title, storageString, defaultHidden }) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    if(localStorage.getItem(storageString + '_hidden') === null && defaultHidden){
      localStorage.setItem(storageString + '_hidden', defaultHidden)
      setShow(!defaultHidden)
    }
    if(localStorage.getItem(storageString + '_hidden') === 'true'){
      setShow(false)
    }
  }, [storageString, show, setShow, defaultHidden])

  const toggle = () => {
    if(show){
      setShow(false)
      localStorage.setItem(storageString + '_hidden', true)
    } else{
      setShow(true)
      localStorage.setItem(storageString + '_hidden', false)
    }
  }

  return (
    <Outer>
      <PrognosisTitle onClick={toggle}>{title}</PrognosisTitle>
      <PrognosisToggle onClick={toggle}>{show ? 'Dölj prognos' : 'Visa prognos'}</PrognosisToggle>
      {show && children}
    </Outer>
  )
}

export default PrognosisWrapper
