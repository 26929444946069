import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfiniteScroll from "react-infinite-scroll-component"

import { STATION_LIST_URL } from '../constants/endpoints'

import ShadowBox from '../components/ShadowBox'
import StationRow from '../components/StationRow'
import ChoiceSelector from '../components/ChoiceSelector'
import HorizontalAd from '../containers/ads/HorizontalAd'

const Positioner = styled.div`
  min-height: 100vh;
  width: 768px;
  margin: 0 0 5px 0;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

`

const SearchTitle = styled.span`
  width: 100%;
  font-size: 16px;
  font-family: 'Averia Serif Libre', cursive;
  font-weight: bold;
  opacity: 0.7;
  margin-right: auto;
  margin-bottom: 12px;
  > a {
    float: right;
    font-size: 12px;
    margin: 2px 4px 0 0;
  }

  @media only screen and (max-width: 768px) {
    font-size: 13px;
    > a {font-size: 11px;}
  }

`

const ListOuter = styled.div`
  width: 100%;
 
`

const ListInner = styled.div`
  width: 100%;
 
`

const SearchBox = styled.input`
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  margin: 1px 0;
  font-size: 25px;
`

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;

  z-index: 10;
  font-size: 100px;
  opacity: 1;
  color: grey;
`

const OfTotalRow = styled.p`
  font-size: 14px;
  font-family: 'Averia Serif Libre', cursive;
  text-align: left;
  opacity: 0.6;
  margin-left: 10px;
  margin-bottom: 0;
`

const chunk_size = 100
const StationList = () => {
  const ad_containers = ["one", "two"]
  const searchTimer = useRef()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [searchString, setSearchString] = useState("")
  const [filterMode, setFilterMode] = useState('alfabetiskt')
  const [filterDirectionMode, setFilterDirectionMode] = useState('rising')

  const [cursor, setCursor] = useState(4)
  const next = () => setCursor(cursor + 2)


  useEffect(() => {
    const searchStringFromStorage = localStorage.getItem('searchString')
    if(searchStringFromStorage) {
      setSearchString(searchStringFromStorage)
    }
    const filterModeFromStorage = localStorage.getItem('filterMode')
    if(filterModeFromStorage) {
      setFilterMode(filterModeFromStorage)
    }
    const filterDirectionModeFromStorage = localStorage.getItem('filterDirectionMode')
    if(filterDirectionModeFromStorage) {
      setFilterDirectionMode(filterDirectionModeFromStorage)
    }


  }, [])

  useEffect(() => {
    clearInterval(searchTimer.current)
    searchTimer.current = setTimeout(() => {
      (async () => {
        setLoading(true)
        localStorage.setItem('searchString', searchString)
	localStorage.setItem('filterDirectionMode', filterDirectionMode)
	localStorage.setItem('filterMode',filterMode)
        const response = await axios({
           url: STATION_LIST_URL,
           params: {
             search: searchString,
             sort: filterMode,
             by: filterDirectionMode
           },
           timeout: 5000
         })

         if(Object.keys(response.data).length !== 0){
           setData(response.data)
           setCursor(4)
         }
         setLoading(false)

      })()
    }, 500)
  }, [searchString, filterMode, filterDirectionMode])

  return (
    <Positioner>

        <ShadowBox small>
          <SearchTitle>Sök mätstationer <a target="_blank" rel="noreferrer" href="https://www.temperatur.nu/info/rapportera-till-temperatur-nu/">Vill du rapportera din temperatur?</a></SearchTitle>
          <SearchBox placeholder="Sök" value={searchString} onChange={e => setSearchString(e.target.value)} />
          <ChoiceSelector title="Sortera mätstationerna:" selectedValue={filterMode} onChange={val => setFilterMode(val)} choices={[
            { name: "Alfabetiskt", icon: "font-case", value: "alfabetiskt" },
            { name: "Temperatur", icon: "temperature-low", value: "temperatur" },
            { name: "Norr/Söder", icon: "compass", value: "latitud" },
          ]} />

          <ChoiceSelector title="Sortera efter:" selectedValue={filterDirectionMode} onChange={val => setFilterDirectionMode(val)} choices={[
            { name: "Stigande", icon: "sort-size-up-alt", value: "rising" },
            { name: "Fallande", icon: "sort-size-down-alt", value: "falling" },
          ]} />

        </ShadowBox>

        {loading && <LoadingWrapper><FontAwesomeIcon icon={['fal', 'spinner-third']} spin /></LoadingWrapper>}

        {!loading && <OfTotalRow>Visar {data.search.numstations} av {data.search.numtotal} mätpunkter. Medeltemperatur i urvalet: {data.search.average}°C</OfTotalRow>}

        <ListOuter>

        <InfiniteScroll
          dataLength={cursor}
          next={next}
          hasMore={true}
        >
          {(!loading && data?.stations) && Array(Math.ceil(data.stations.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => data.stations.slice(begin, begin + chunk_size)).slice(0, cursor).map(section =>
            <ListInner>

              {section.map(station =>
                <StationRow
                  key={station.id}
                  title={station.title}
                  kommun={station.kommun}
                  value={station.temp}
                  id={station.id}
                />)
              }

              {/* {ad_containers.length !== 0 && <HorizontalAd type={ad_containers.pop()} />} */}
            </ListInner>
          )}
        </InfiniteScroll>

        </ListOuter>
    </Positioner>
  )
}

export default StationList
