import React from 'react'

const PageCommon = ({ children }) => {

  return (
    <>{children}</>
  )
}

export default PageCommon
