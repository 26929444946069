
export const setGeolocationAvailable = (bool) => {
  return dispatch => {
    dispatch({ type: 'SET_GEOLOCATION_AVAILABLE', payload: bool })
  }
}

export const setCurrentGeolocation = (pos) => {
  return dispatch => {
    localStorage.setItem('saved_geolocation', JSON.stringify(pos))
    dispatch({ type: 'SET_CURRENT_GEOLOCATION', payload: pos })
  }
}

export const setChoosenStation = (name) => {
  return dispatch => {
    localStorage.setItem('saved_station', name)
    dispatch({ type: 'SET_CHOOSEN_STATION', payload: name })
  }
}

export const checkSavedStation = () => {
  return dispatch => {
    const saved_geolocation = JSON.parse(localStorage.getItem('saved_geolocation'))
    const saved_station = localStorage.getItem('saved_station')

    if(saved_geolocation !== 'null' && saved_geolocation && Object.keys(saved_geolocation).length !== 0){
      console.log('saved_geolocation', saved_geolocation)
      dispatch({ type: 'SET_CURRENT_GEOLOCATION', payload: saved_geolocation })
    }
    if (saved_station !== 'null') {
      console.log('saved_station', saved_station)
      dispatch({ type: 'SET_CHOOSEN_STATION', payload: saved_station })
    }

  }
}
