import React from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  position: relative;
  max-width: 96vw;
  padding: 15px;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  box-shadow: ${({ small }) => small
  ? "0 4px 3px rgba(0,0,0,0.05), 0 -1px 3px rgba(0,0,0,0.06)"
  : "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"};

`

const ShadowBox = ({ children, small, style, ...other }) =>
    <Outer small={small} style={style} {...other}>
      {children}
    </Outer>

export default ShadowBox
