import { v4 as uuidv4 } from 'uuid'

const init = {
  geolocation_available: false,
  current_geolocation: {},
  choosen_station: null
}

const uiReducer = (state = init, action) => {
  switch (action.type) {

    case 'SET_GEOLOCATION_AVAILABLE':
      return { ...state, geolocation_available: action.payload }

    case 'SET_CURRENT_GEOLOCATION':
      return { ...state, current_geolocation: action.payload }

    case 'SET_CHOOSEN_STATION':
      return { ...state, choosen_station: action.payload }

    default:
      return state
  }
}

export default uiReducer
