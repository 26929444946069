import React, { useEffect, Fragment } from 'react'
import {
  Route,
  Switch,
  BrowserRouter,
  useHistory
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import PageCommon from './containers/PageCommon'
import Home from './scenes/Home'
import AllStations from './scenes/AllStations'
import SingleStation from './scenes/SingleStation'
import Map from './scenes/Map'
import Closest from './scenes/Closest'

export const history = createBrowserHistory()

function ScrollToTop({ children }) {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    });
    return () => {
      unlisten()
    }
  }, [])

  return <Fragment>{children}</Fragment>
}

const RouterSwitcher = () => {
  return (
    <PageCommon>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/matplatser" component={AllStations} />
          <Route exact path="/karta" component={Map} />
          <Route exact path="/narmaste" component={Closest} />
          <Route exact path="/:name" component={SingleStation} />
        </Switch>
      </ScrollToTop>
    </PageCommon>
  )
}


const MainRouter = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <RouterSwitcher />
  </BrowserRouter>
)


export default MainRouter
