import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import { GET_FROM_COORDS_URL } from '../constants/endpoints'

import ShadowBox from '../components/ShadowBox'
import Button from '../components/Button'
import SearchBar from '../containers/SearchBar'

import { setGeolocationAvailable, setCurrentGeolocation, setChoosenStation } from '../actions/ui'

const Positioner = styled.div`
  margin: 0 0 5px 0;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
`

const Title = styled.span`
  font-size: 28px;
  font-family: 'Averia Serif Libre', cursive;
  text-align: center;
  font-weight: bold;
  opacity: 0.8;
`

const Description = styled.span`
  font-size: 16px;
  font-family: 'Averia Serif Libre', cursive;
  text-align: center;
  font-weight: bold;
  opacity: 0.8;
  padding: 5px 0 15px 0;
`

const SearchWrap = styled.div`
  margin-top: 10px;
`

const Divider = styled.span`
  opacity: 0.75;
  margin-top: 8px;
  margin-bottom: -4px;
  font-size: 14px;
  font-family: 'Averia Serif Libre', cursive;
`

const LocationImage = styled.img`
  width: 200px;
  margin: 15px 0;
`

const PositionRetriver = () => {
  const dispatch = useDispatch()
  const geolocation_available = useSelector(state => state.ui.geolocation_available)

  const getPos = useCallback(() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      dispatch(setCurrentGeolocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        altitude: position.coords.altitude
      }))

      const response = await axios({
         url: GET_FROM_COORDS_URL,
         params: {lat: position.coords.latitude, lon: position.coords.longitude},
         timeout: 5000
       })

       if(response?.data?.stations[0]?.id){
          dispatch(setChoosenStation(response?.data?.stations[0]?.id))
       }

    })
  }, [dispatch])

  useEffect(() => {
    // check if geolocation is even available in the browser
    if ("geolocation" in navigator) {dispatch(setGeolocationAvailable(true))}
  }, [dispatch, geolocation_available])

  return (
    <Positioner>
      <ShadowBox>
        <Inner>
          <LocationImage style={{cursor: 'pointer'}} onClick={getPos} src={`${process.env.PUBLIC_URL}/map.png`} height="200" width="200" />
          <Title onClick={getPos}>Ange din favorit på temperatur.nu</Title>
          <Description onClick={getPos}>När du satt en favorit hittar du snabbt och enkelt tillbaka till just den mätpunkten.</Description>
          {geolocation_available && <Button onClick={getPos}>Hitta närmaste mätstation från din position</Button>}
          {geolocation_available && <Divider>eller</Divider>}
          <SearchWrap>
            <SearchBar onChange={name => dispatch(setChoosenStation(name))} />
          </SearchWrap>
        </Inner>
      </ShadowBox>
    </Positioner>
  )
}

export default PositionRetriver
