import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Page } from '../style/wrappers'

import Header from '../containers/Header'
import HorizontalAd from '../containers/ads/HorizontalAd'
import VerticalDesktopAd from '../containers/ads/VerticalDesktopAd'
import StationList from '../containers/StationList'
import Footer from '../components/Footer'

const OuterPage = styled.div`
  display: flex;
`

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5% 0;
`

const AllStations = () => {
  return (
    <OuterPage>
      <VerticalDesktopAd left />
      <Page>
        <Helmet>
          <title>Alla mätstationer | Temperatur.nu</title>
        </Helmet>
        <Outer>
          <Header />
          <HorizontalAd type="custom0" />
          <StationList />
	   <HorizontalAd type="custom1" />
	  <Footer />
        </Outer>
      </Page>
      <VerticalDesktopAd right />
    </OuterPage>
  )
}

export default AllStations
