import styled from 'styled-components'

export const Page = styled.div`
  width: 768px;
  padding-top: ${({ topPad }) => topPad};
  margin: 0;


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1300px) {
    margin: 0 auto;
  }

`
