import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { SEARCH_URL } from '../constants/endpoints'


const Outer = styled.div`
  position: relative;
  width: 320px;
`

const SeachInput = styled.input`
  height: 54px;
  width: 100%;
  padding: 10px 10px;

  font-size: 20px;
  border-radius: 5px;
  border: 1px solid;
`

const Dropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 320px;
  background: white;
  margin: 5px 0;
  padding: 1px 0;
  border: 1px solid;
  border-radius: 5px;
  z-index: 10;
`

const Cluster = styled.div`
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 1px 0;
  padding: 8px 4px 10px 4px;
  background: rgb(240,238,235);
  > span {
    font-family: 'Averia Serif Libre', cursive;
    margin-bottom: 2px;
  }

`

const Item = styled.div`
  margin: 1px 0;
  padding: 4px 6px;
  background: white;
  border: 1px solid;
  border-radius: 3px;
  margin-right: auto;
  font-size: 18px;
  font-family: 'Averia Serif Libre', cursive;
  cursor: pointer;

  &:hover{
    background: #f9ca24;
  }
`

const SearchBar = ({ onChange }) => {
  const searchTimer = useRef()
  const [searchString, setSearchString] = useState("")
  const [showResults, setShowResults] = useState(false)
  const [results, setResults] = useState({})

  useEffect(() => {
      if(searchString !== "") {
        clearInterval(searchTimer.current)
        searchTimer.current = setTimeout(() => {
          (async () => {
            const response = await axios({
                url: SEARCH_URL,
                params: {
                  search: searchString,
                },
                timeout: 5000
              })

              if(response.data.stations) {
                setResults(Object.keys(response.data.stations).reduce((acc, val) => {
                  return {...acc, [response.data.stations[val].kommun]: {...acc[response.data.stations[val].kommun], [val]: response.data.stations[val]}}
                  }, {})
                )
              } else {
                setResults({})
              }

          })()
        }, 500)
      }


  }, [searchString]) // eslint-disable-line

  return (
    <Outer>

      <SeachInput
        placeholder="Sök mätpunkt"
        onChange={e => setSearchString(e.target.value)}
        onFocus={() => setShowResults(true)}
        onBlur={() => setTimeout(() => setShowResults(false), 250)}>
      </SeachInput>

      {(Boolean(searchString.length) && showResults) && <Dropdown>

        {Object.keys(results).slice(0,5).map(s => <Cluster key={s}>
          <span>{s}</span>
          {Object.keys(results[s]).map(key => <Item key={key} onClick={() => onChange(results[s][key].id)}>{results[s][key].title}</Item>)}
        </Cluster>)}

        {Object.keys(results).length === 0 && <span style={{ marginTop: 5, marginBottom: 5 }}>Inga resultat</span>}

      </Dropdown>}
    </Outer>
  )
}

export default SearchBar
